








































































































import { Component, Vue } from 'vue-property-decorator';
import { API_URL } from '@/config';

@Component
export default class Main extends Vue {
  longUrl: string = '';

  revokePwd: string = '';

  shortUrl: string = '';

  valid = false;

  loading = false;

  clicked = false;

  snackbar = false;

  rule = [(v: string) => !!v || 'Please input the URL!'];

  prefix = ' ';

  radioes = [
    {
      prefix: ' ',
      comments: 'Customize',
    },
    {
      prefix: 'https://',
      comments: 'https://',
    },
    {
      prefix: 'http://',
      comments: 'http://',
    },
  ];

  generated = false;

  short() {
    if (this.valid) {
      this.loading = true;
      this.clicked = true;
      const urlencoded = new URLSearchParams();
      let toShort = this.longUrl;
      if (this.prefix === 'https://' || this.prefix === 'http://') {
        if (!this.longUrl.startsWith(this.prefix)) {
          toShort = this.prefix + this.longUrl;
        }
      }
      urlencoded.append('urls', toShort);
      fetch(API_URL, {
        method: 'PUT',
        body: urlencoded,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.code === 201) {
            this.shortUrl = data?.data?.shortUrl;
            this.revokePwd = data?.data?.revokePwd;
            this.generated = true;
            this.loading = false;
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    } else {
      (this.$refs.urlInput as Element & { focus: () => void }).focus();
    }
  }
}
