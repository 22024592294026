






import { Component, Vue } from 'vue-property-decorator';
import Main from '@/components/Main.vue'; // @ is an alias to /src

@Component({
  components: {
    Main,
  },
})
export default class MainView extends Vue {}
